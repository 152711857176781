var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"subnav-placeholder"},[_c('div',{ref:"subnav",staticClass:"subnav py-2"},[_c('a',{class:['mx-2', _vm.currentMode == 'free' ? 'active' : ''],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchToPage('free')}}},[_vm._v("Free Clips")]),_vm._v(" "),_c('a',{class:['mx-2', _vm.currentMode == 'pro' ? 'active' : ''],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchToPage('pro')}}},[_vm._v("Unscreen Pro")])])]),_vm._v(" "),_c('div',{class:{
      'upload-page': true,
      'has-uploads': _vm.uploads.length,
      'd-none': _vm.currentMode != 'free',
    },attrs:{"oncontextmenu":_vm.t.rightclick ? 'return true;' : 'return false;'}},[(_vm.uploads.length > 0)?_c('div',{staticClass:"text-center pt-md-5 pb-3",staticStyle:{"padding-top":"5rem"}},[_c('button',{staticClass:"btn btn-outline-primary btn-lg",on:{"click":_vm.uploadFromFile}},[_c('i',{staticClass:"fas fa-upload"}),_vm._v("\n        "+_vm._s(_vm.t.upload_file)+"\n      ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.uploads.length > 0)?_c('div',{staticClass:"upload-list"},[_vm._l((_vm.uploads),function(upload){return _c('upload',_vm._b({key:upload.key,attrs:{"t":_vm.t},on:{"finished":function($event){upload.status = 'finished';
            _vm.processQueue();},"error":function($event){upload.status = 'error';
            _vm.processQueue();},"delete":function($event){return _vm.deleteUpload(upload.key)},"submitToPro":_vm.submitToPro}},'upload',upload,false))}),_vm._v(" "),_c('div',{staticClass:"mt-4 text-center small",staticStyle:{"color":"#98a0a6"}},[_vm._v("\n          "+_vm._s(_vm.t.dont_forget_to_download)+"\n        ")])],2):_vm._e(),_vm._v(" "),(_vm.uploads.length == 0)?_c('div',{staticClass:"empty-state"},[_c('h1',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.t.upload_page_heading_html)}})]),_vm._v(" "),_c('div',{staticClass:"mx-auto mt-5",staticStyle:{"max-width":"350px"}},[_c('upload-widget',{attrs:{"t":_vm.t},on:{"uploadFile":_vm.uploadFromFile,"uploadUrl":_vm.uploadFromUrl}})],1),_vm._v(" "),_c('div',{staticClass:"mt-4 text-center mx-auto",staticStyle:{"font-size":"60%","opacity":"0.5","max-width":"385px"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.t.upload_terms_html)}})])]):_vm._e()])]),_vm._v(" "),_c('div',{class:{ 'd-none': _vm.currentMode != 'pro' }},[_c('ProList',_vm._b({ref:"prolist",attrs:{"t":_vm.t},on:{"uploadUrl":_vm.uploadFromUrl}},'ProList',_vm.t.pro,false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }