<template>
  <div>
    <div class="text-center mb-2 row">
      <div v-if="sidebyside" class="col-md">
        <slot name="side"></slot>
      </div>
      <div class="col-md">
        <ul class="nav nav-tabs justify-content-center pb-2" role="tablist">
          <li class="nav-item">
            <a
              v-if="original"
              :class="{ 'nav-link': true, active: modeTab == 'original' }"
              href="#"
              @click.prevent="changeMode('original')"
            >
              Original
            </a>
          </li>
          <li class="nav-item">
            <a
              :class="{ 'nav-link': true, active: modeTab == 'transparent' }"
              href="#"
              @click.prevent="changeMode('transparent');"
            >
              Removed Background
            </a>
          </li>
        </ul>
        <div ref="editor" class="editor transparency-grid">
          <div v-if="progress" class="preview-progress progress">
            <div
              :aria-valuenow="progress * 100"
              :style="{ width: `${progress * 100}%` }"
              aria-valuemax="100"
              aria-valuemin="0"
              class="progress-bar progress-bar-striped"
              role="progressbar"
            >
              {{ Math.round(progress * 100) }}%
            </div>
          </div>
          <div class="fg">
            <div
              v-if="bgTab == 'original'"
              style="
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 20;
              "
            >
              <slot name="original"></slot>
            </div>
            <slot name="foreground"></slot>
          </div>
          <video
            v-if="bg.type == 'video'"
            ref="bgvideo"
            :style="{
              maxWidth: bgVideoDisplayDimensions.width,
              maxHeight: bgVideoDisplayDimensions.height,
            }"
            autoplay="autoplay"
            class="bg-video"
            crossOrigin="anonymous"
            loop="loop"
            muted="muted"
            playsinline="playsinline"
            @loadedmetadata="bgVideoMetadataLoaded"
          >
            <source :src="bg.url" type="video/mp4" />
          </video>
          <div
            v-if="bg.type == 'image'"
            :style="{ backgroundImage: `url('${bg.url}')` }"
            class="bg-image"
          ></div>
          <div
            v-if="bg.type == 'color'"
            :style="{ backgroundColor: bg.value }"
            class="bg-color"
          ></div>
        </div>
      </div>
    </div>
    <slot name="below"></slot>

    <div
      v-if="!showBackgroundEditor && modeTab == 'transparent'"
      id="show_background_editor"
      class="mb-2 text-center"
    >
      <span
        class="text-primary"
        style="cursor: pointer; font-size: 0.875rem; font-weight: 600;"
        @click.prevent="toggleBackgroundEditor()"
      >
        Change Background
        <i class="fas fa-chevron-down"></i>
      </span>
    </div>
    <transition name="fade">
      <div v-if="showBackgroundEditor && modeTab == 'transparent'">
        <div class="mb-2 flex-row d-flex justify-content-between">
          <div class="d-flex flex-column flex-md-row" style="font-size: 0.875rem">
            <span class="pb-md-0 pb-3">Change Background:</span>
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: bgTab == 'video' }"
                href="#"
                @click.prevent="showBgTab('video')"
              >
                Video
              </a>
            </li>
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: bgTab == 'color' }"
                href="#"
                @click.prevent="showBgTab('color')"
              >
                Color
              </a>
            </li>
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: bgTab == 'image' }"
                href="#"
                @click.prevent="showBgTab('image')"
              >
                Image
              </a>
            </li>
          </ul>
          </div>
          <ul class="nav nav-tabs d-flex flex-column-reverse flex-md-row text-right" role="tablist" style="font-size: 0.875rem">
            <li v-if="bg.type != 'transparent'">
              <span
                class="nav-link"
                style="cursor: pointer; font-weight: 600; color: #ff7272;"
                @click.prevent="showBgTab('transparent')"
              >
                <i class="fas fa-trash-alt"></i> Reset
              </span>
            </li>
            <li class="pb-md-0 pb-3 ml-2">
              <span
                class="nav-link text-primary"
                style="cursor: pointer; font-weight: 600;"
                @click.prevent="toggleBackgroundEditor()"
              >
                <i class="fas fa-times"></i> Close
              </span>
            </li>
          </ul>
        </div>

        <div style="min-height: 125px; position: relative">
          <a
            v-if="bgScrollPosition != 0"
            class="bg-scroll-btn bg-scroll-btn-left"
            href="#"
            @click.prevent="scrollBgLeft"
          >
            <i class="fas fa-chevron-left fa-2x"></i>
          </a>
          <a
            v-if="bgScrollPosition < bgScrollSize"
            class="bg-scroll-btn bg-scroll-btn-right"
            href="#"
            @click.prevent="scrollBgRight"
          >
            <i class="fas fa-chevron-right fa-2x"></i>
          </a>

          <div
            v-if="bgTab == 'image'"
            ref="bgscroll"
            class="bg-options"
            @scroll.passive="
              updateBgScrollPosition();
              updateBgScrollSize();
            "
          >
            <button
              class="bg-option bg-option-primary"
              @click="selectBgVideoFile"
            >
              <i
                class="fas fa-cloud-upload mb-1"
                style="font-size: 160%;"
              ></i>
              <div style="font-size: 0.7rem">Upload Background</div>
            </button>
            <button
              v-for="image in backgroundImages"
              :class="{
                'bg-option': true,
                active: bg.type == 'image' && bg.url == image.image_url,
              }"
              :style="{ backgroundImage: `url('${image.thumbnail_url}')` }"
              @click="
                bg = {
                  type: 'image',
                  url: image.image_url,
                  source: { type: 'image', image: image },
                }
              "
            >
              <span class="bg-option-label">
                {{ image.title }}
              </span>
            </button>
          </div>

          <div
            v-if="bgTab == 'video'"
            ref="bgscroll"
            class="bg-options"
            @scroll.passive="
              updateBgScrollPosition();
              updateBgScrollSize();
            "
          >
            <button
              class="bg-option bg-option-primary"
              @click="selectBgVideoFile"
            >
              <i
                class="fas fa-cloud-upload mb-1"
                style="font-size: 160%;"
              ></i>
              <div style="font-size: 0.7rem">Upload Background</div>
            </button>
            <!-- <button
              class="bg-option bg-option-primary"
              @click="gifOverlay = true"
            >
              <i
                class="fas fa-search mb-1"
                style="font-size: 160%;"
              ></i>
              <div style="font-size: 0.7rem">Search Background</div>
            </button>
            <gifpicker
              v-if="gifOverlay"
              context="background"
              @close="gifOverlay = false"
              @select="
                setBgGif($event);
                gifOverlay = false;
              "
            /> -->

            <button
              v-for="video in backgroundVideos"
              :class="{
                'bg-option': true,
                active: bg.type == 'video' && bg.url == video.video_url,
              }"
              :style="{ backgroundImage: `url('${video.thumbnail_url}')` }"
              @click="
                setBgVideo(video.video_url, { type: 'video', video: video })
              "
            >
              <span class="bg-option-label">
                {{ video.title }}
              </span>
            </button>
          </div>

          <div
            v-if="bgTab == 'color'"
            ref="bgscroll"
            class="bg-options"
            @scroll.passive="
              updateBgScrollPosition();
              updateBgScrollSize();
            "
          >
            <button
              v-for="color in backgroundColors"
              :class="{
                'bg-option': true,
                active: bg.type == 'color' && bg.value == color.css,
              }"
              :style="{ backgroundColor: color.css }"
              @click="bg = { type: 'color', value: color.css }"
            >
              <span class="bg-option-label">
                {{ color.title }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { pickFile } from "../../src/pick_file";

import Gifpicker from "./gifpicker";

import $ from "jquery";

const backgroundVideos = require("../../assets/background_videos.json");
const backgroundImages = require("../../assets/background_images.json");
const backgroundColors = require("../../assets/background_colors.json");

const bgScrollStep = 300; // pixels

export default {
  props: {
    sidebyside: {
      type: Boolean,
      default: false,
    },
    original: {
      type: Boolean,
      default: true,
    },
    progress: Number,
    width: Number,
    height: Number,
    initialBg: {
      type: Object,
      default() {
        return { type: "transparent" };
      },
    },
  },
  components: { Gifpicker },
  data() {
    var tab = "video";
    if (this.initialBg.type == "color") tab = "color";
    if (this.initialBg.type == "image") tab = "image";
    return {
      bgTab: tab,
      modeTab: "transparent",
      bgVideoWidth: "none",
      bgVideoHeight: "none",
      bg: this.initialBg,
      gifOverlay: false,
      backgroundVideos: backgroundVideos,
      backgroundImages: backgroundImages,
      backgroundColors: backgroundColors,
      bgScrollPosition: 0,
      bgScrollSize: 0,
      bgWidth: 0,
      bgHeight: 0,
      editorWidth: 0,
      editorHeight: 0,
      showBackgroundEditor: false,
    };
  },
  mounted() {
    this.$emit("change", { bg: this.bg });
    this.$nextTick(() => {
      this.updateEditorSize();
      this.updateBgScrollSize();
    });
    window.addEventListener("resize", this.updateEditorSize);
  },
  onBeforeDestroy() {
    window.removeEventListener("resize", this.updateEditorSize);
  },
  computed: {
    bgVideoDisplayDimensions() {
      var widthRatio = this.width / this.bgWidth;
      var heightRatio = this.height / this.bgHeight;

      if (heightRatio >= widthRatio) {
        var height = this.height;

        if (height > this.editorHeight) {
          height = this.editorHeight;
        }

        // otherwise 1px bar can appear because of subpixel alignment issues
        var evenHeight = height % 2 == 0 ? height : height + 1;

        return { width: "none", height: `${evenHeight}px` };
      } else {
        var width = this.width;

        if (width > this.editorWidth) {
          width = this.editorWidth;
        }

        // otherwise 1px bar can appear because of subpixel alignment issues
        var evenWidth = width % 2 == 0 ? width : width + 1;

        return { width: `${evenWidth}px`, height: "none" };
      }
    },
  },
  watch: {
    bg(newBg) {
      this.$emit("change", { bg: newBg });
    },
  },
  methods: {
    toggleBackgroundEditor() {
      this.showBackgroundEditor = !this.showBackgroundEditor;
    },
    updateEditorSize() {
      this.editorWidth = this.$refs.editor.clientWidth;
      this.editorHeight = this.$refs.editor.clientHeight;
    },
    getConfig() {
      return this.bg;
    },
    updateBgScrollPosition() {
      var scroll = this.$refs.bgscroll;
      if (scroll) {
        this.bgScrollPosition = scroll.scrollLeft;
      } else {
        this.bgScrollPosition = 0;
      }
    },
    updateBgScrollSize() {
      var scroll = this.$refs.bgscroll;
      if (scroll) {
        this.bgScrollSize = scroll.scrollWidth - scroll.clientWidth;
      } else {
        this.bgScrollSize = 0;
      }
    },
    showBgTab(tab) {
      if(tab == 'transparent') {
        this.bg = {type: "transparent"}
      } else {
        this.bgTab = tab;
      }
      this.$nextTick(() => {
        this.updateBgScrollSize();
        this.updateBgScrollPosition();
      });
    },
    changeMode(mode) {
      this.modeTab = mode;
      this.bgTab = (mode == 'original' ? 'original' : 'video');
      this.$nextTick(() => {
        this.updateBgScrollSize();
        this.updateBgScrollPosition();
      });
    },
    scrollBgLeft() {
      $(this.$refs.bgscroll)
        .stop(true, true)
        .animate({
          scrollLeft: Math.max(this.bgScrollPosition - bgScrollStep, 0),
        });
    },
    scrollBgRight() {
      $(this.$refs.bgscroll)
        .stop(true, true)
        .animate({
          scrollLeft: Math.min(
            this.bgScrollPosition + bgScrollStep,
            this.bgScrollSize
          ),
        });
    },
    selectBgVideoFile() {
      pickFile({ additionalTypes: [".jpg", ".jpeg", ".png"] }).then((file) => {
        if (file.type.startsWith("video/")) {
          var url = URL.createObjectURL(file);
          // TODO: URL.revokeObjectURL(this.fileUrl);
          this.setBgVideo(url, { type: "file", file: file });
        } else {
          var url = URL.createObjectURL(file);
          // TODO: URL.revokeObjectURL(this.fileUrl);
          this.bg = {
            type: "image",
            url: url,
            mimeType: file.type,
            source: {
              type: "file",
              file: file,
            },
          };
        }
      });
    },
    setBgGif(gif) {
      this.bg = {
        type: "image",
        url: gif.full_gif,
        mimeType: "image/gif",
        source: {
          type: "gif",
          gif: gif,
        },
      };
    },
    bgVideoMetadataLoaded() {
      this.bgWidth = this.$refs.bgvideo.videoWidth;
      this.bgHeight = this.$refs.bgvideo.videoHeight;
    },
    setBgVideo(url, source) {
      this.bg = {
        type: "video",
        url: url,
        mimeType: "video/",
        source: source,
      };
      this.$nextTick(() => {
        this.$refs.bgvideo.load();
        this.$refs.bgvideo.play();
      });
    },
  },
};
</script>

<style scoped>
.editor {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border: 1px solid black;
}

.bg-scroll-btn {
  position: absolute;
  height: 80px;
  padding-top: 30px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  opacity: 0.6;
  z-index: 20;
}

.bg-scroll-btn:hover {
  opacity: 0.9;
}

.bg-scroll-btn-left {
  left: -15px;
}

.bg-scroll-btn-right {
  right: -15px;
}

@media (min-width: 768px) {
  .bg-scroll-btn-left {
    left: 0;
  }

  .bg-scroll-btn-right {
    right: 0;
  }
}

.bg-video {
  min-width: 100%;
  min-height: 100%;

  width: auto;
  height: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.bg-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.bg-color {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.fg {
  position: relative;
  z-index: 10;
}

.fg > * {
  display: block;
}

.preview-progress {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  z-index: 20;
  height: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
}

.preview-progress .progress-bar {
  transition: none;
}

.nav-tabs .nav-link {
  font-size: 90%;
  margin-left: 0rem !important;
  margin-right: 0.3rem !important;
}

@media (min-width: 768px) {
  .nav-tabs .nav-link {
    font-size: 100%;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

.nav-tabs .nav-link:not(.active) {
  color: #d9d9d9;
}

.nav-tabs .nav-link:not(.active):hover {
  color: #fff;
}

.bg-options {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .bg-options {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.bg-option {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border: 2px solid #202020;
  margin: 5px 10px 15px 0px;
  cursor: pointer;
  background-size: cover;
  position: relative;
  border-radius: 7px;
  outline: none;
}

.bg-option:not(.active):hover {
  opacity: 0.8;
}

.bg-option-primary {
  border: 1px solid #2699fb;
  color: #2699fb;
  background: transparent;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-option-primary:not(.active):hover {
  color: #fff;
  background: #2699fb;
  opacity: 1;
}

.bg-option.active {
  border-color: #2699fb;
}

.bg-option-label {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  color: #bac0c4;
  font-size: 60%;
  margin-top: 2px;
}

.fade-enter-active {
  transition: opacity 0.3s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
