<template>
  <div class="card card-with-shadow card-rounded-max card-without-border upload-widget-card">
    <div class="card-body text-center">
      <div class="my-2 my-md-5">
        <button class="btn btn-primary btn-lg" v-on:click="$emit('uploadFile')" style="min-width: 190px;">
          <i class="fas fa-upload"></i>
          {{ t.upload_file }}
        </button>
      </div>
      <!-- <div class="mt-2 mb-2" style="color: #9f9f9f; font-weight: bold;">
        or
      </div> -->
      <!-- <div class="mb-4 mb-md-5" style="position: relative;">
        <button class="btn btn-outline-primary btn-lg" @click="gifOverlay = true" style="min-width: 190px;">Search GIF</button>

        <gifpicker context="foreground" v-if="gifOverlay" @close="gifOverlay = false" @select='gifSelected' />
      </div> -->
      <div style="font-size: 80%;" class="text-muted">
        Supported formats: {{ supportedFormats.join(', ') }}
      </div>
    </div>
    <div class="card-footer small text-muted text-center d-none d-md-block">
      {{ t.paste_text_start }}
      <a href="#" class="text-muted select-photo-url-btn" style="text-decoration: underline;" v-on:click.prevent="$emit('uploadUrl')">{{ t.paste_text_url}}</a>
      {{ t.paste_text_end }}

      <span class="d-inline-block px-1 rounded ml-2" style="background: transparent; border: 1px solid #9F9F9F;">{{ modifierKey }}</span> + <span class="d-inline-block px-1 rounded" style="background: transparent; border: 1px solid #9F9F9F;">v</span>
    </div>
  </div>
</template>

<script>
import { uploadGif } from '../../src/upload';

import FileTypes from '../../src/file_types';
import Gifpicker from './gifpicker';


export default {
  props: {
    t: Object
  },
  components: { Gifpicker },
  data: () => {
    var exts = FileTypes.getSupportedFormats().map((format) => `.${format.ext}`);

    return {
      gifOverlay: false,
      supportedFormats: exts,
    }
  },
  computed: {
    modifierKey() {
      var isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
      return isMac ? "cmd" : "ctrl";
    }
  },
  methods: {
    gifSelected(gif) {
      uploadGif(gif);
    },
  }
}
</script>

<style scoped>
.upload-widget-card {
  background: #202020;

}
.upload-widget-card .card-footer {
  background: #323232;
}
</style>
