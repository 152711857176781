<template>
  <div>
    <modal @close="$emit('close')" :mobileFullWidth="true" :mobileFullHeight="true">
      <div class="gifmodal d-flex flex-column">
        <div>
          <form @submit.prevent="searchGifs" class="d-flex p-2">
            <div class="flex-grow-1">
              <div class="input-group mx-auto" style="max-width: 350px;">
                <input type="text" class="form-control" placeholder="Search Tenor" v-model="searchTerm" ref="gifInput">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit" style="height: 43px;">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-1 pr-1 pl-2">
              <a href="#" @click.prevent="$emit('close')" style="color: #d9d9d9;"><i class="fas fa-times fa-2x"></i></a>
            </div>
          </form>
        </div>
        <div class="flex-grow-1" style="overflow: auto;" ref="gifscroller">
          <div class="d-md-none container-fluid">
            <div class="row">
              <div class="col px-1">
                <giflist :gifs="gifsSplit2[0]" @select="$emit('select', $event);"
                  :format="supportsWebp ? 'webp' : 'gif'" />
              </div>
              <div class="col px-1">
                <giflist :gifs="gifsSplit2[1]" @select="$emit('select', $event);"
                  :format="supportsWebp ? 'webp' : 'gif'" />
              </div>
            </div>
          </div>
          <div class="d-none d-md-block container-fluid">
            <div class="row">
              <div class="col px-1">
                <giflist :gifs="gifsSplit3[0]" @select="$emit('select', $event);"
                  :format="supportsWebp ? 'webp' : 'gif'" />
              </div>
              <div class="col px-1">
                <giflist :gifs="gifsSplit3[1]" @select="$emit('select', $event);"
                  :format="supportsWebp ? 'webp' : 'gif'" />
              </div>
              <div class="col px-1">
                <giflist :gifs="gifsSplit3[2]" @select="$emit('select', $event);"
                  :format="supportsWebp ? 'webp' : 'gif'" />
              </div>
            </div>
          </div>

        </div>
        <div class="py-1 text-center">
          <img src="/via_tenor_logo_white.png" alt="Search via Tenor" class="img-fluid mx-auto gif-search-provider-logo">
        </div>

      </div>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery';
import Giflist from './giflist';
import Modal from './modal';
import { getConfig } from '../../src/get_meta';

function indexOfSmallest(a) {
  var lowest = 0;
  for (var i = 1; i < a.length; i++) {
    if (a[i] < a[lowest]) lowest = i;
  }
  return lowest;
}

export default {
  props: {
    context: String,
  },
  components: { Giflist, Modal },
  data: () => {
    return {
      searchTerm: "",
      gifs: [],
      gifsSplit2: [],
      gifsSplit3: [],
      supportsWebp: false,
    }
  },
  mounted() {
    Modernizr.on('webp', (result) => {
      this.supportsWebp = result;
    });
    this.$refs.gifInput.focus();
    this.searchGifs();
  },
  methods: {
    gifServiceResponse(res) {
      if (!res || !res.data) return [];
      var gifs = [];
      res.data.forEach((gif) => {
        if (gif && gif.images && gif.images.fixed_width && gif.images.fixed_width.url && gif.images.fixed_width.webp && gif.images.original && gif.images.original.url) {
          gifs.push({
            id: gif.id,
            title: gif.title,
            preview_gif: gif.images.fixed_width.url,
            preview_webp: gif.images.fixed_width.webp,
            preview_width: gif.images.fixed_width.width,
            preview_height: gif.images.fixed_width.height,
            full_gif: gif.images.original.url,
            attribution: gif.username,
          });
        }
      });
      return gifs;
    },
    searchGifs(e) {
      var baseUrl = getConfig()['gif_api_url'];
      $.ajax(
        `${baseUrl}?context=${this.context}&q=${encodeURIComponent(this.searchTerm)}`
      ).done((res) => {
        this.setGifs(this.gifServiceResponse(res));
        //this.status = (this.search_results.length == 0) ? "No results for " +  this.search_query: "";
      }).fail((res) => {
        this.setGifs([]);
      });
    },
    setGifs(gifs) {
      this.gifs = gifs;

      var heights;

      this.gifsSplit3 = [[], [], []];
      heights = [0, 0, 0];
      this.gifs.forEach((gif) => {
        var idx = indexOfSmallest(heights);
        this.gifsSplit3[idx].push(gif);
        heights[idx] += Number(gif.preview_height);
      });

      this.gifsSplit2 = [[], [], []];
      heights = [0, 0];
      this.gifs.forEach((gif) => {
        var idx = indexOfSmallest(heights);
        this.gifsSplit2[idx].push(gif);
        heights[idx] += Number(gif.preview_height);
      });

      this.$refs.gifscroller.scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2490;
  background-color: rgba(0, 0, 0, 0.5);
}

.gifmodal {
  background: #535353;
  height: 100%;
}

.gif-search-provider-logo {
  max-height: 1.5rem;
}

@media(min-width: 768px) {
  .gifmodal {
    width: 660px;
    height: 500px;
    border-radius: 5px;
  }
}
</style>
