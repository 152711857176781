<template>
  <div>
    <div class="subnav-placeholder">
      <div ref="subnav" class="subnav py-2">
        <a
          href="#"
          @click.prevent="switchToPage('free')"
          :class="['mx-2', currentMode == 'free' ? 'active' : '']"
          >Free Clips</a
        >
        <a
          href="#"
          @click.prevent="switchToPage('pro')"
          :class="['mx-2', currentMode == 'pro' ? 'active' : '']"
          >Unscreen Pro</a
        >
      </div>
    </div>
    <div
      v-bind:class="{
        'upload-page': true,
        'has-uploads': uploads.length,
        'd-none': currentMode != 'free',
      }"
      :oncontextmenu="t.rightclick ? 'return true;' : 'return false;'"
    >
      <div
        v-if="uploads.length > 0"
        class="text-center pt-md-5 pb-3"
        style="padding-top: 5rem"
      >
        <button
          class="btn btn-outline-primary btn-lg"
          v-on:click="uploadFromFile"
        >
          <i class="fas fa-upload"></i>
          {{ t.upload_file }}
        </button>
        <!-- <button
          class="btn btn-outline-primary btn-lg"
          @click="gifOverlay = true"
        >
          Search GIF
        </button>
        <gifpicker
          context="foreground"
          v-if="gifOverlay"
          @close="gifOverlay = false"
          @select="
            gifPicked($event);
            gifOverlay = false;
          "
        /> -->
      </div>
      <div class="container">
        <div class="upload-list" v-if="uploads.length > 0">
          <upload
            v-for="upload in uploads"
            v-bind="upload"
            :key="upload.key"
            @finished="
              upload.status = 'finished';
              processQueue();
            "
            @error="
              upload.status = 'error';
              processQueue();
            "
            @delete="deleteUpload(upload.key)"
            v-bind:t="t"
            @submitToPro="submitToPro"
          />
          <div class="mt-4 text-center small" style="color: #98a0a6">
            {{ t.dont_forget_to_download }}
          </div>
        </div>

        <div class="empty-state" v-if="uploads.length == 0">
          <h1>
            <span v-html="t.upload_page_heading_html"></span>
          </h1>
          <div style="max-width: 350px" class="mx-auto mt-5">
            <upload-widget
              v-on:uploadFile="uploadFromFile"
              v-on:uploadUrl="uploadFromUrl"
              v-bind:t="t"
            />
          </div>
          <div
            style="font-size: 60%; opacity: 0.5; max-width: 385px"
            class="mt-4 text-center mx-auto"
          >
            <span v-html="t.upload_terms_html"></span>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ 'd-none': currentMode != 'pro' }">
      <ProList
        v-bind="t.pro"
        ref="prolist"
        v-bind:t="t"
        @uploadUrl="uploadFromUrl"
      />
    </div>
  </div>
</template>

<script>
import Upload from "./upload";
import UploadWidget from "./upload_widget";
import Gifpicker from "./gifpicker";
import ProList from "../pro/list";

import { pickFile } from "../../src/pick_file";
import isMobileOrTablet from "../../src/is_mobile_or_tablet";

import FileTypes from "../../src/file_types";

import { uploadFile, uploadUrl, showUploadPage } from "../../src/upload";

const maxConcurrent = 1;

export default {
  props: {
    t: Object,
    mode: String,
  },
  components: { Upload, UploadWidget, Gifpicker, ProList },
  data() {
    return {
      gifOverlay: false,
      uploads: [],
      currentMode: this.mode,
    };
  },
  mounted() {
    const lecBanner = document.querySelector("#lecBanner");
    console.log(lecBanner);
    if (lecBanner) {
      const navbar = document.querySelector(".navbar");
      const offset = lecBanner.clientHeight + navbar.clientHeight;
      this.$refs.subnav.style.top = `${offset}px`;
    }
  },
  computed: {
    processingCount() {
      return this.uploads.filter(
        (upload) =>
          upload.status != "queued" &&
          upload.status != "error" &&
          upload.status != "finished"
      ).length;
    },
  },
  methods: {
    switchToPage(mode) {
      showUploadPage(mode);
    },
    submitToPro(submission) {
      if (!this.t.pro.signed_in) {
        location.href = this.t.pro.login_url;
        return;
      }
      if (submission.file) {
        uploadFile(submission.file, "pro");
      } else if (submission.url) {
        uploadUrl(submission.url, "pro");
      }
    },
    uploadFromUrl() {
      var url = prompt(`${this.t.video_url}:`);
      this.urlPicked(url);
    },
    gifPicked(gif) {
      this.enqueue({
        url: gif.full_gif,
        basename: gif.title,
      });
    },
    urlPicked(url) {
      if (url == null || url == "") return;
      if (!url.match(/^https?:\/\/.+$/)) {
        alert(this.t.invalid_video_url);
        return;
      }
      var basenameRegex = /\/([^\/.\?#:]+)[^\/]*$/;
      var basenameExtr = basenameRegex.exec(url);
      basenameExtr = basenameExtr ? basenameExtr[1] : "";

      if (this.currentMode == "free") {
        this.enqueue({ url, basename: basenameExtr });
      } else if (this.currentMode == "pro") {
        this.$refs.prolist.urlPicked({ url, basename: basenameExtr });
      }
    },
    uploadFromFile() {
      var pickOpts = {};
      if (isMobileOrTablet()) pickOpts.accept = "video/*";
      pickFile(pickOpts).then((file) => {
        this.filePicked(file);
      });
    },
    filePicked(file) {
      if (this.currentMode == "free") {
        var supported = FileTypes.getSupportedFormats();
        var supportedMime = supported.map((format) => format.mime);
        if (supportedMime.indexOf(file.type) == -1) {
          alert(
            `This file format is not supported by this browser.\nSupported: ${supported
              .map((format) => format.ext)
              .join(", ")}`
          );
          return;
        }
        var basenameRegex = /([^.]+)(\.[^.]+)?$/;
        var basenameExtr = basenameRegex.exec(file.name);
        this.enqueue({
          file,
          basename: basenameExtr[1],
        });
      } else if (this.currentMode == "pro") {
        this.$refs.prolist.filePicked(file);
      }
    },
    enqueue(upload) {
      this.uploads.unshift({
        key: this.generateKey(),
        status: "queued",
        ...upload,
      });
      this.processQueue();

      //this.$nextTick(() => {
      //  var uploadlist = this.$refs['uploadlist'];
      //  if(!uploadlist) return;
      //  $('html, body').animate({
      //    scrollTop: $(uploadlist.$el).offset().top - 90
      //  }, 300);
      //})
    },
    processQueue() {
      if (this.uploads.length == 0 || this.processingCount >= maxConcurrent)
        return;
      var oldest = this.uploads
        .slice()
        .reverse()
        .find((upload) => upload.status == "queued");
      if (!oldest) return;
      this.process(oldest);
    },
    process(upload) {
      upload.status = "processing";
    },
    generateKey() {
      return `${new Date().getTime()}-${Math.round(
        1000000000 * Math.random()
      )}`;
    },
    deleteUpload(key) {
      var idx = this.uploads.findIndex((upload) => upload.key == key);
      if (idx == -1) return;
      this.uploads.splice(idx, 1);
    },
  },
};
</script>

<style scoped>
.upload-page {
  padding-bottom: 40px;
  /*transition: background 300ms;*/
}
.upload-page.has-uploads {
  /*background: #F7F9FA;*/
}
.empty-state {
  text-align: center;
  padding: 60px 0;
}
@media (min-height: 800px) {
  .empty-state {
    padding: 100px 0 100px 0;
  }
}
@media (min-height: 900px) {
  .empty-state {
    padding: 100px 0 170px 0;
  }
}
@media (min-height: 1000px) {
  .empty-state {
    padding: 100px 0 220px 0;
  }
}

.subnav-placeholder {
  height: 40px;
}

.subnav {
  background: #d9d9d9;
  position: fixed;
  text-align: center;
  left: 0;
  right: 0;
  margin-top: -2px;
  z-index: 500;
}

@media (min-width: 992px) {
  .subnav {
    z-index: 1500;
  }
}

.subnav a {
  color: #454545;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  font-size: 90%;
}

.subnav a.active {
  color: #2699fb;
  border-color: #2699fb;
}

@media (min-width: 992px) {
  .subnav {
    padding-left: 275px;
    text-align: left;
    transition: top 0.5s;
  }

  .subnav:after {
    bottom: 100%;
    left: 375px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(217, 217, 217, 0);
    border-bottom-color: #d9d9d9;
    border-width: 8px;
    margin-left: -8px;
  }
}
</style>
